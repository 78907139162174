import React from "react"
import { Column, Container, Row } from "../../components/Grid"
import Layout from "../../components/Layout"
import Section from "../../components/Section"
import SEO from "../../components/Seo"
import SubFooterImage from "../../images/theme/img-subfooter-educators.jpg"

const InteractivePage = ({ location }) => {
  const queryParameters = new URLSearchParams(location.search)
  const keyIndex = queryParameters.get("keyIndex")
  let [key, index] = keyIndex ? keyIndex.split("_") : ""
  index = Number.parseInt(index)

  return (
    <Layout>
      {/***************  ABOVE FOOTER CALLOUT ***************/}
      <Section>
        <Container fullwidth>
          <Row>
            <Column size={10} offset={2}>
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: "0",
                  overflow: "hidden",
                }}
              >
                <script
                  src="https://studio.stornaway.io/embed/v1/player.js"
                  defer
                ></script>
                <iframe
                  src="https://player.stornaway.io/embed/09b331bf"
                  frameBorder="0"
                  allowFullScreen
                  allow="accelerometer; gyroscope;autoplay;"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default InteractivePage
